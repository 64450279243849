import React, { useState } from "react"
import { Button, Card, Row } from "antd"
import { Typography } from "antd"
import GenesisTermsConditions from "./GenesisDiscolsure/GenesisTermsConditions"
import GenesisAuthorizationDisclosures from "./GenesisDiscolsure/GenesisAuthorizationDisclosures"
import { useTheme } from "../../hooks/useTheme"
import ConcoraConsentModal from "../modals/ConcoraConsentModal"

const { Title, Text } = Typography

function GenesisDiscosure({ acceptOffer, offer }) {
  const { colors } = useTheme()
  const [isOpenConsent, setIsOpenConsent] = useState(false)

  return (
    <Card>
      <div style={{ margin: 0, padding: 0 }}>
        <Title level={5}>Terms & Conditions</Title>
        <GenesisTermsConditions />
        <br />
        <Title level={5}>Application Authorization Disclosures</Title>
        <GenesisAuthorizationDisclosures bankName={offer?.bank_name || ""} />
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://paypossible.com/genesis"
          style={{ fontWeight: "bold", fontSize: 16, marginTop: 10 }}
        >
          Print Disclosures
        </a>
        <br />
      </div>
      <Row justify="center" style={{ paddingTop: 20 }}>
        <Button onClick={acceptOffer} type="primary">
          Submit Application
        </Button>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 12 }}>
          By clicking "Submit Application" below, you confirm you are applying
          for a Concora™ Credit Account issued by {offer?.bank_name} and you
          have read and agreed to the Terms & Conditions,{" "}
          <Text
            type="text"
            style={{
              color: colors.green,
              fontSize: 12,
              cursor: "pointer",
            }}
            onClick={() => setIsOpenConsent(true)}
          >
            Consent to Electronic Communications
          </Text>
          , and Application Authorization Disclosures above.
        </Text>
      </Row>
      <ConcoraConsentModal
        showModal={isOpenConsent}
        close={() => setIsOpenConsent(false)}
      />
    </Card>
  )
}
export default GenesisDiscosure
