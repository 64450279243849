import React, { useCallback, useEffect, useState, useMemo } from "react"
import { Card, Row, Button, Alert } from "antd"

import { initialize } from "@chargeafter/payment-sdk"
import { useHistory } from "react-router-dom"

import { Spinner } from "../core/Spinner"
import {
  getSdkParams,
  validateCitiSdkAuth,
  checkOutSdk,
} from "../../services/offer.service"
import { useApply } from "../../hooks/useApply"
import { OfferOverNotification } from "../descriptions"
import { getCurrentDate } from "../../utils/formatters"

const CitiOffer = ({ offer, isOverAmount }) => {
  const history = useHistory()
  const { lead, merchant } = useApply()

  const [errorStaus, setErrorStatus] = useState("")
  const [loading, setLoading] = useState(false)

  const goLead = () => {
    history.push(`/apps/${offer.lead.id}`)
  }

  const handleAuth = useCallback(async () => {
    setErrorStatus("")
    const response = await getSdkParams(offer.id)
    if (response.success) {
      const {
        api_key,
        merchant_id,
        lender_id,
        session_token,
        store_id,
        test_mode,
      } = response.data
      const cfg = {
        apiKey: api_key,
        delegatedMerchantId: merchant_id,
        preferences: {
          language: "en",
          currency: "USD",
        },
      }
      if (store_id) {
        cfg.storeId = store_id
      }

      try {
        const caObject = await initialize(
          cfg,
          test_mode ? "sandbox" : "production"
        )
        caObject.payments.present("checkout", {
          callback: async (token, data, status) => {
            await validateCitiSdkAuth(offer.id, data, status)
            if (
              status.code === "AUTHENTICATION_COMPLETED" ||
              status.code === "AUTHENTICATION_SUCCEEDED"
            ) {
              goLead()
            } else {
              setErrorStatus(status.message)
            }
          },
          intent: {
            lenderId: lender_id,
            type: "Authenticate",
          },
          sessionToken: session_token,
        })
      } catch (ex) {
        setErrorStatus(ex.message)
      }
    }
    //eslint-disable-next-line
  }, [offer])

  const redirectToLoan = useCallback(async () => {
    if (lead.loans.length === 0) {
      history.push(`/apps/${lead.id}`)
    } else if (lead.loans.length === 1) {
      history.push(`/loans/${lead.loans[0].id}`)
    } else {
      const matchingLoan = lead.loans.find(
        (obj) => obj.originator.key === offer.originator.key
      )
      if (matchingLoan) {
        history.push(`/loans/${matchingLoan.id}`)
      } else {
        history.push(`/loans`)
      }
    }
  }, [history, lead, offer])

  const handleAcceptOffer = useCallback(async () => {
    setLoading(true)
    const response = await getSdkParams(offer.id)
    if (response.success) {
      const { api_key, merchant_id, session_token, store_id, test_mode } =
        response.data
      const cfg = {
        apiKey: api_key,
        delegatedMerchantId: merchant_id,
        preferences: {
          language: "en",
          currency: "USD",
        },
      }
      if (store_id) {
        cfg.storeId = store_id
      }
      try {
        const caObject = await initialize(
          cfg,
          test_mode ? "sandbox" : "production"
        )
        caObject.payments.present("checkout", {
          callback: async (token, data, status) => {
            await checkOutSdk(offer.id, status, data, token)
            if (!status || status.code === "PENDING") {
              redirectToLoan()
            } else {
              setErrorStatus(status.message)
            }
          },
          sessionToken: session_token,
        })
      } catch (ex) {
        setErrorStatus(ex.message)
      } finally {
        setLoading(false)
      }
    }
  }, [offer, redirectToLoan])

  useEffect(() => {
    if (offer.status === "applied") {
      redirectToLoan()
    }
  }, [offer, redirectToLoan])

  const purchaseAmount = useMemo(() => {
    if (offer) {
      const mPayment = Number(
        offer.intro_monthly_payment.replace(/\$\s?|(,*)/g, "")
      )
      const mTerm = Number(offer.term.replace(/[^\d]/g, ""))
      return Number(mPayment * mTerm).toFixed(2)
    }
  }, [offer])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      {errorStaus && (
        <Alert
          message={errorStaus}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 7, width: "100%", textAlign: "left" }}
        />
      )}
      <OfferOverNotification lead={lead} isOverAmount={isOverAmount} />
      <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
        {offer.status === "existing" && (
          <>
            <Row justify="center">
              <img
                src={offer.originator.image_url}
                alt="lender-logo"
                height={60}
              />
            </Row>

            <Row justify="center">
              <span
                style={{
                  marginTop: 50,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                It looks like you have an existing account with CitiPay
              </span>
            </Row>
            <Row justify="center">
              <span
                style={{
                  marginTop: 10,
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Login to see offers
              </span>
            </Row>

            <Row justify="center" style={{ marginTop: 40 }}>
              <Button type="primary" onClick={handleAuth}>
                Login
              </Button>
            </Row>
          </>
        )}
        {offer.status === "offered" && (
          <>
            <Row justify="center">
              <p style={{ fontSize: 25, marginBottom: 10, fontWeight: 600 }}>
                Pre-Qualified for
              </p>
            </Row>
            <Row justify="center">
              <img
                src={offer.originator.image_url}
                alt="lender-logo"
                height={60}
              />
            </Row>
            <Row
              justify="center"
              style={{
                fontSize: 18,
                marginTop: 10,
                fontWeight: 500,
              }}
            >
              Credit limit: {offer.max_amount_offered}
            </Row>

            <Row
              justify="left"
              style={{
                maxWidth: 400,
                width: "100%",
                margin: "20px auto",
                textAlign: "left",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <div style={{ marginBottom: 15 }}>
                <b>
                  Great news, {lead?.personal?.first_name || ""}{" "}
                  {lead?.personal?.last_name || ""}!
                </b>
              </div>
              <div style={{ marginBottom: 7 }}>
                You’re pre-qualified for a Citi Pay® Credit account with a{" "}
                {offer.amount} credit limit. You can also take advantage of
                promotional financing:
              </div>
              <div
                style={{
                  border: "1px solid #5b96c5",
                  borderRadius: 10,
                  padding: 14,
                  width: "100%",
                }}
              >
                <strong>No Interest, if paid in full within 6 Months</strong> on
                each {merchant.name} purchase of $180+ with your{" "}
                <strong>Citi Pay</strong> Credit account.*
                <div style={{ fontSize: 11, marginTop: 10, marginBottom: 10 }}>
                  Interest will be charged to your account from the purchase
                  date if the purchase balance is not paid in full within 6
                  months.
                </div>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Purchase Amount:</div>
                  <div>$ {purchaseAmount}</div>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Sugg. Payments:</div>
                  <div>{offer.payment}</div>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Term:</div>
                  <div>{offer.term}</div>
                </Row>
              </div>
            </Row>

            <Row justify="center">
              <Button type="primary" onClick={handleAcceptOffer}>
                Check Eligibility
              </Button>
            </Row>
            <Row justify="center">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: 30,
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                Disclosures
              </div>
            </Row>
            <Row justify="center">
              <div
                style={{
                  marginTop: 10,
                  textAlign: "left",
                  maxWidth: 600,
                  width: "100%",
                }}
              >
                *Subject to credit approval. No interest if paid in full within
                the promotional period. Interest will be charged to your account
                if the purchase balance is not paid in full within the
                promotional period. <br />
                <br />
                This Deferred Interest Plan purchase is subject to the following
                promotional terms: you will not be charged any interest on the
                amount of the purchase if that amount is paid in full within 6
                months. <br />
                <br />
                If the amount of the purchase is not paid in full by the end of
                the promotional period, interest charges will be imposed from
                the purchase date at the purchase rate on your account, which is
                % APR. This APR is accurate as of {getCurrentDate()}. This APR
                is variable and will vary with the market based on the Prime
                Rate. Your Card Agreement, this Promotional Disclosure and
                applicable law govern the terms of this Deferred Interest Plan
                purchase.
                <br />
                <br />
                The suggested monthly payment stated is an estimate based on
                listed purchase amount divided by the number of months in the
                promotional period. It excludes sales tax, is rounded to the
                next highest cent and does not include additional payments that
                may be required for an existing balance or future purchases. In
                order to pay your promotional balance by the end of the period
                you may have to make a payment each month that is higher than
                your required minimum payment.
              </div>
            </Row>
          </>
        )}
      </Card>
    </>
  )
}
export default CitiOffer
