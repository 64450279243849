const copy = {
  core: {
    lander:
      "Check to see if you're eligible to finance your purchase through PayPossible!",
  },
  opploans: {
    account: "",
    purchase: "",
    payments: "",
    help: "",
    use: "",
    personal_loan: {
      disclaimer: "",
      next_steps: "",
      summary: "",
    },
  },
  default: {
    personal_loan: {
      disclaimer: "",
      next_steps:
        "Review the offer on the following page.  If you wish to apply, you will be redirected to the lender's page to complete your application",
      summary:
        "You've been pre-qualified for a personal loan.  A personal loan will fund you the approved amount directly to you which you will pay over time back to the lender with interest.",
    },
  },
  finwise: {
    account:
      "Congratulations on your American First Finance Account with Finwise Bank.",
    purchase:
      "Your American First account allows you to apply your balance to the purchase of a single item. After approving your purchase, you will be required to review and sign a purchase contract to authorize your transaction. To complete a purchase:",
    payments:
      "Your payments will be made automatically to American First Finance through the debit account you provided.",
    help: "If you require assistance regarding making payments to your account, please contact American First Finance at 1-855-721-1188",
    use: "Your American First account allows you to apply your balance to the balance of a purchase.  After approving your purchase, you will be required to review and sign a purchase contract to authorize your transaction. To complete a purchase:",
    personal_loan: {
      disclaimer:
        "**The transaction described is an installment loan originated by FinWise Bank, a Utah-chartered bank located in Murray, Utah, Member FDIC. Installment loan amounts typically range from $200 to $5,000. APR ranges from 35.99% to 180%. Installment loan lengths range from 6 to 24 months. Example: A $2,000 installment loan, with a $100.00 origination fee, repayable in 24 monthly payments would have an APR of 167.9%, 23 monthly payments of $292.48, and one final monthly payment of $292.32. The origination fee is included in the estimated periodic payment. The APR is based on the amount financed, individual creditworthiness, and other factors. Actual terms may vary. The approval and amount are both subject to underwriting criteria and additional verification.",
      next_steps: `Review the offer and Disclosures on the following page.  Additional information is required to apply, Click "Apply Now" to complete your application, once your account is approved continue your purchase as directed by the merchant`,
      summary:
        "A retail installment contract is a contract between you and the retail merchant for payments in exchange for goods or services. The retail merchant will deliver the goods and services to you according to the installment contract.",
    },
  },
  americanfirst: {
    account: "Congratulations on your American First Finance Account.",
    purchase:
      "Your American First account allows you to apply your balance to your transaction. After approving your order, you will be required to review and sign documents to authorize your transaction. To complete:",
    payments:
      "Your payments will be made automatically to American First Finance through the debit account you provided.",
    help: "If you require assistance regarding making payments to your account, please contact American First Finance at 1-855-721-1188",
    use: "Your American First account allows you to apply your balance to the balance of an order.  After approving your order, you will be required to review and sign an agreement to authorize your transaction. To complete a order:",
    lease_to_own: {
      disclaimer:
        "Subject to approval and verification. The transaction advertised is a rental-purchase agreement. The processing fee or initial rent payment due upon execution of the agreement is $50 plus tax. Ownership rights are not acquired unless the Total Cost of Ownership is paid, and the merchandise is rented for the minimum initial term disclosed in the agreement as a specified number of payment periods. The total number of payments necessary to obtain ownership of the property ranges from 6 to 104 payments, depending on your agreement and payment frequency. The timing of scheduled payments may be weekly, bi-weekly, semi-monthly, or monthly depending on when you are paid. As an example, the Total Cost of Ownership for a couch that costs $1,000 could be $2,050, including the $1,000 cash price plus applicable tax and fees (e.g., processing fee). In this example, after the initial $50 payment plus tax, 52 full, timely payments of $38.46 plus sales tax per week over 12 months may be required for ownership. Late fees, NSF fees, and Liability Damage Waiver (LDW) fees may apply where permitted. Terms and conditions subject to change at any time without prior disclosure or notice.",
      next_steps:
        'Review American First Finance’s Offer and Disclosures on the following page. Additional information is required to apply. Click "View Offer" to complete your application. If your application is approved, continue with making your selection as directed by the merchant.',
      summary:
        "Lease-to-own is an alternative payment option to financing. American First Finance will buy the merchandise and lease it to you. Once you've fulfilled the terms of your agreement the merchandise is yours.",
    },
    personal_loan: {
      disclaimer:
        "**The transaction described is an installment loan originated by FinWise Bank, a Utah-chartered bank located in Murray, Utah, Member FDIC. Installment loan amounts typically range from $200 to $5,000. APR ranges from 35.99% to 180%. Installment loan lengths range from 6 to 24 months. Example: A $2,000 installment loan, with a $100.00 origination fee, repayable in 24 monthly payments would have an APR of 167.9%, 23 monthly payments of $292.48, and one final monthly payment of $292.32. The origination fee is included in the estimated periodic payment. The APR is based on the amount financed, individual creditworthiness, and other factors. Actual terms may vary. The approval and amount are both subject to underwriting criteria and additional verification.",
      next_steps: `Review the offer and Disclosures on the following page.  Additional information is required to apply, Click "Apply Now" to complete your application, once your account is approved continue your purchase as directed by the merchant`,
      summary:
        "A retail installment contract is a contract between you and the retail merchant for payments in exchange for goods or services. The retail merchant will deliver the goods and services to you according to the installment contract.",
    },
  },
  genesis: {
    account:
      "Congratulations on creating your Genesis Credit Account.  You will receive an email from Genesis FS Card Services to the email associated with your application.  You will also receive an account packet in the mail within 7 business days which contains all relevant account information.",
    purchase:
      "Your Genesis Credit Account is a line of credit which works similar to a credit card.  Your remaining balance shows your remaining buying power and will be updated as you make purchases and payments. To complete a purchase:",
    payments: `Payments will be made directly to Genesis Credit Card Services.  Follow the instructions provided in the Genesis FS Card Services email, your account packet, or setup your account on the Genesis Website.`,
    use: "Your Genesis Credit Account is a line of credit which works similar to a credit card.  Your remaining balance shows your remaining buying power and will be updated as you make purchases and payments. To complete a purchase:",
    help: "If you have not received the email within 24 hours, please contact Genesis FS Card Services at 1-866-502-6439. They are available from 6:00 AM-6:00 PM PST, seven days a week.",
    credit_line: {
      next_steps:
        "Review the offer, Terms and Conditions, and Disclosures before submitting your application. If you are approved, then continue your purchase as directed by the merchant.",
      summary:
        "This is a private label credit card account for use at a specified merchant which offers low monthly minimum payments and the ability to use your available credit for future purchase. See full Account terms and conditions for details.",
    },
    personal_loan: {
      next_steps:
        "Review the offer, Terms and Conditions, and Disclosures before submitting your application. If you are approved, then continue your purchase as directed by the merchant.",
      summary:
        "This is a private label credit card account for use at a specified merchant which offers low monthly minimum payments and the ability to use your available credit for future purchase. See full Account terms and conditions for details.",
    },
  },
  citizens: {
    account:
      "Congratulations on your Citizens Pay Line of Credit.  Line of credit is an agreement that permits an account holder to borrow money repeatedly up to a set dollar limit while repaying a portion of the current balance due in regular payments. Each payment, minus the interest and fees charged, replenishes the amount available to the account holder.",
    purchase:
      "To complete a purchase follow the steps below in coordination with the merchant:",
    payments: `Payments will be made directly to Citizens Pay.  Follow the insturctions provided by Citizens Pay in your account packet to setup and manage your payments`,
    use: "Citizens Use",
    help: "If you require assistance regarding your account please contact Citizens Pay at 1-877-405-2344",
    credit_line: {
      next_steps:
        "Review the offer, Terms & Conditions and Disclosures on the following page.  Click the “Accept Offer” button to accept the offer, once your account is approved continue your purchase as directed by the merchant.",
      summary:
        "Line of credit is an agreement that permits an account holder to borrow money repeatedly up to a set dollar limit while repaying a portion of the current balance due in regular payments. Each payment, minus the interest and fees charged, replenishes the amount available to the account holder.",
    },
  },
  fortiva: {
    account:
      "Congratulations on creating your Fortiva Retail Credit Account issued by The Bank of Missouri. Account statements you receive in the mail will show as Fortiva Retail Credit, not the merchant where the account was opened. You will also receive a copy of your Cardholder Agreement via email from Fortiva Retail Credit.",
    purchase:
      "Your Fortiva Credit Account is a line of credit which works similar to a credit card.  Your remaining balance shows your remaining buying power and will be updated as you make purchases and payments. \n To complete a purchase:",
    payments:
      "Payments will be made directly to Fortiva Retail Credit. Please follow the instructions provided to you via email from Fortiva Retail Credit. You can also setup an Automatic Payment Plan, and you can download the Fortiva Account Center App in the Apple App Store or on Google Play.",
    use: "Your Fortiva Credit Account is a line of credit which works similar to a credit card.  Your remaining balance shows your remaining buying power and will be updated as you make purchases and payments. To complete a purchase:",
    help: "For any questions please contact Fortiva Retail Credit at (866) 290-2835 or visit www.myfortiva.com.",
    credit_line: {
      next_steps:
        "Review the offer, Terms & Conditions and Disclosures on the following page.  Click the “Accept Offer” button to accept the offer, once your account is approved continue your purchase as directed by the merchant.",
      summary:
        "Use your Fortiva Retail Credit account to make purchases up to your available credit subject to keeping your account in good standing. Your monthly payment will be a minimum of $30 or an amount equal to a percentage of your highest outstanding balance since your account was opened or since your account balance was zero, plus the greater of any past due amount or any balance that exceeds your Credit Limit. See your cardholder agreement for more details.",
    },
  },
  ownlease: {
    account: "Congratulations on your Own.Lease Account.",
    purchase:
      "Your American First account allows you to apply your balance to your transaction. After approving your order, you will be required to review and sign documents to authorize your transaction. To complete:",
    payments:
      "Your payments will be made automatically to Own.Lease through the debit account you provided.",
    help: "If you require assistance regarding making payments to your account, please contact Own.Lease at 1-855-721-1188",
    use: "Your American First account allows you to apply your balance to the balance of an order.  After approving your order, you will be required to review and sign an agreement to authorize your transaction. To complete a order:",
    lease_to_own: {
      disclaimer:
        "Subject to approval and verification. The transaction advertised is a rental-purchase agreement. The processing fee or initial rent payment due upon execution of the agreement is $50 plus tax. Ownership rights are not acquired unless the Total Cost of Ownership is paid, and the merchandise is rented for the minimum initial term disclosed in the agreement as a specified number of payment periods. The total number of payments necessary to obtain ownership of the property ranges from 6 to 104 payments, depending on your agreement and payment frequency. The timing of scheduled payments may be weekly, bi-weekly, semi-monthly, or monthly depending on when you are paid. As an example, the Total Cost of Ownership for a couch that costs $1,000 could be $2,050, including the $1,000 cash price plus applicable tax and fees (e.g., processing fee). In this example, after the initial $50 payment plus tax, 52 full, timely payments of $38.46 plus sales tax per week over 12 months may be required for ownership. Late fees, NSF fees, and Liability Damage Waiver (LDW) fees may apply where permitted. Terms and conditions subject to change at any time without prior disclosure or notice.",
      next_steps:
        'Review Own.Lease’s Offer and Disclosures on the following page. Additional information is required to apply. Click "View Offer" to complete your application. If your application is approved, continue with making your selection as directed by the merchant.',
      summary:
        "Lease-to-own is an alternative payment option to financing. Own.Lease will buy the merchandise and lease it to you. Once you've fulfilled the terms of your agreement the merchandise is yours.",
    },
    personal_loan: {
      disclaimer:
        "**The transaction described is an installment loan originated by FinWise Bank, a Utah-chartered bank located in Murray, Utah, Member FDIC. Installment loan amounts typically range from $200 to $5,000. APR ranges from 35.99% to 180%. Installment loan lengths range from 6 to 24 months. Example: A $2,000 installment loan, with a $100.00 origination fee, repayable in 24 monthly payments would have an APR of 167.9%, 23 monthly payments of $292.48, and one final monthly payment of $292.32. The origination fee is included in the estimated periodic payment. The APR is based on the amount financed, individual creditworthiness, and other factors. Actual terms may vary. The approval and amount are both subject to underwriting criteria and additional verification.",
      next_steps: `Review the offer and Discolsures on the following page.  Additional information is required to apply, Click "Apply Now" to complete your application, once your account is approved continue your purchase as directed by the merchant`,
      summary:
        "A retail installment contract is a contract between you and the retail merchant for payments in exchange for goods or services. The retail merchant will deliver the goods and services to you according to the installment contract.",
    },
  },
  mybambu: {
    account: "Congratulations on your Own.Lease Account.",
    purchase:
      "Your American First account allows you to apply your balance to your transaction. After approving your order, you will be required to review and sign documents to authorize your transaction. To complete:",
    payments:
      "Your payments will be made automatically to Own.Lease through the debit account you provided.",
    help: "If you require assistance regarding making payments to your account, please contact Own.Lease at 1-855-721-1188",
    use: "Your American First account allows you to apply your balance to the balance of an order.  After approving your order, you will be required to review and sign an agreement to authorize your transaction. To complete a order:",
    lease_to_own: {
      disclaimer:
        "Subject to approval and verification. The transaction advertised is a rental-purchase agreement. The processing fee or initial rent payment due upon execution of the agreement is $50 plus tax. Ownership rights are not acquired unless the Total Cost of Ownership is paid, and the merchandise is rented for the minimum initial term disclosed in the agreement as a specified number of payment periods. The total number of payments necessary to obtain ownership of the property ranges from 6 to 104 payments, depending on your agreement and payment frequency. The timing of scheduled payments may be weekly, bi-weekly, semi-monthly, or monthly depending on when you are paid. As an example, the Total Cost of Ownership for a couch that costs $1,000 could be $2,050, including the $1,000 cash price plus applicable tax and fees (e.g., processing fee). In this example, after the initial $50 payment plus tax, 52 full, timely payments of $38.46 plus sales tax per week over 12 months may be required for ownership. Late fees, NSF fees, and Liability Damage Waiver (LDW) fees may apply where permitted. Terms and conditions subject to change at any time without prior disclosure or notice.",
      next_steps:
        'Review Own.Lease’s Offer and Disclosures on the following page. Additional information is required to apply. Click "View Offer" to complete your application. If your application is approved, continue with making your selection as directed by the merchant.',
      summary:
        "Lease-to-own is an alternative payment option to financing. Own.Lease will buy the merchandise and lease it to you. Once you've fulfilled the terms of your agreement the merchandise is yours.",
    },
    personal_loan: {
      disclaimer:
        "**The transaction described is an installment loan originated by FinWise Bank, a Utah-chartered bank located in Murray, Utah, Member FDIC. Installment loan amounts typically range from $200 to $5,000. APR ranges from 35.99% to 180%. Installment loan lengths range from 6 to 24 months. Example: A $2,000 installment loan, with a $100.00 origination fee, repayable in 24 monthly payments would have an APR of 167.9%, 23 monthly payments of $292.48, and one final monthly payment of $292.32. The origination fee is included in the estimated periodic payment. The APR is based on the amount financed, individual creditworthiness, and other factors. Actual terms may vary. The approval and amount are both subject to underwriting criteria and additional verification.",
      next_steps: `Review the offer and Discolsures on the following page.  Additional information is required to apply, Click "Apply Now" to complete your application, once your account is approved continue your purchase as directed by the merchant`,
      summary:
        "A retail installment contract is a contract between you and the retail merchant for payments in exchange for goods or services. The retail merchant will deliver the goods and services to you according to the installment contract.",
    },
  },
  uown: {
    account: "",
    purchase: "",
    payments: "",
    help: "",
    use: "",
    lease_to_own: {
      disclaimer: "",
      next_steps:
        "Review Uown Leasing's Offer and Disclosures on the following page. Additional information is required to apply. Click 'View Offer' to complete your application. If you application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option to financing. Uown Leasing will buy the merchandise and lease it to you. Once you've fulfilled the terms of your agreement, the merchandise is yours.",
    },
    personal_loan: {
      disclaimer: "",
      next_steps:
        "Review Uown Leasing's Offer and Disclosures on the following page. Additional information is required to apply. Click 'View Offer' to complete your application. If you application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option to financing. Uown Leasing will buy the merchandise and lease it to you. Once you've fulfilled the terms of your agreement, the merchandise is yours.",
    },
  },
  flexshopper: {
    account: "",
    purchase: "",
    payments: "",
    help: "",
    use: "",
    lease_to_own: {
      disclaimer: "",
      next_steps:
        "Review FlexShopper disclosures on the following page. Additional information is required to apply. Click apply to complete your application. If your application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option. FlexShopper will buy the merchandise and lease it to you. Once you have fulfilled the terms of your agreement, the merchandise is yours.",
    },
    personal_loan: {
      disclaimer: "",
      next_steps:
        "Review FlexShopper disclosures on the following page. Additional information is required to apply. Click apply to complete your application. If your application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option. FlexShopper will buy the merchandise and lease it to you. Once you have fulfilled the terms of your agreement, the merchandise is yours.",
    },
  },
  "flexshopper-external": {
    account: "",
    purchase: "",
    payments: "",
    help: "",
    use: "",
    lease_to_own: {
      disclaimer: "",
      next_steps:
        "Review FlexShopper disclosures on the following page. Additional information is required to apply. Click apply to complete your application. If your application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option. FlexShopper will buy the merchandise and lease it to you. Once you have fulfilled the terms of your agreement, the merchandise is yours.",
    },
    personal_loan: {
      disclaimer: "",
      next_steps:
        "Review FlexShopper disclosures on the following page. Additional information is required to apply. Click apply to complete your application. If your application is approved, continue with making your selection as directed by the merchant.",
      summary:
        "Lease-to-own is an alternative payment option. FlexShopper will buy the merchandise and lease it to you. Once you have fulfilled the terms of your agreement, the merchandise is yours.",
    },
  },
  mock: {
    account: "",
    purchase: "",
    payments: "",
    help: "",
    use: "",
    personal_loan: {
      disclaimer: "",
      next_steps: "",
      summary: "",
    },
  },
}

export default copy
