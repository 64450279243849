import React, { useEffect, useState } from "react"
import { Card, Row, Typography, Button } from "antd"

import { useApply } from "../../hooks/useApply"

const { Text } = Typography

function FlexshopperOffer({ offer, disclaimer, acceptOffer }) {
  const { merchantId, lead } = useApply()

  const [cartTotal, setCartTotal] = useState(0)
  const [offerTotal, setOfferTotal] = useState(0)
  const [isOver, setIsOver] = useState(false)

  useEffect(() => {
    if (offer?.amount) {
      const value = Number(offer.amount.replace(/\D/g, ""))
      setOfferTotal(value)
    }
  }, [offer])

  useEffect(() => {
    if (lead?.cart?.total) {
      const value = Number(lead.cart?.total)
      setCartTotal(value)
    }
  }, [lead])

  useEffect(() => {
    if (merchantId === "icon" && cartTotal > 0 && offerTotal > 0) {
      if (cartTotal > offerTotal) {
        setIsOver(true)
      } else {
        setIsOver(false)
      }
    } else {
      setIsOver(false)
    }
  }, [cartTotal, offerTotal, merchantId])

  return (
    <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
      {isOver && (
        <Row
          justify="center"
          style={{
            marginTop: 20,
            marginBottom: 30,
            fontSize: 15,
            margin: 0,
            fontWeight: 600,
          }}
        >
          Your Cart Total is Over You Approval Amount, Please Select Cancel
          Checkout and Edit the Cart to be Under Your Approval Amount Below
        </Row>
      )}
      <Row justify="center">
        <img src={offer.originator.image_url} alt="lender-logo" height={60} />
      </Row>

      <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
        <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>
          You've Been Pre-Qualified* By {offer.originator.name}
        </p>
      </Row>

      <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>{offer.amount}</p>
      <br />

      {offer.term ? (
        <div>
          <b>Obtain Ownership in Up To:&nbsp;&nbsp;</b>
          {offer.term}
          &nbsp;&nbsp;
        </div>
      ) : null}

      <Row justify="center" style={{ marginTop: 20 }}>
        <Button type="primary" onClick={acceptOffer} disabled={isOver}>
          Continue
        </Button>
      </Row>

      <p
        style={{
          marginTop: 15,
          fontStyle: "italic",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        * An approval and spending limit are subject to underwriting criteria
        and additional verification.
      </p>
      <Row justify="center" style={{ marginTop: 10 }}>
        {
          <Row
            justify="center"
            style={{
              marginTop: 10,
              marginBottom: 10,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            <Text>{disclaimer || offer.originator.disclaimer}</Text>
          </Row>
        }
      </Row>
    </Card>
  )
}
export default FlexshopperOffer
