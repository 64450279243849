import React, { useEffect, useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import {
  PayPalScriptProvider,
  PayPalButtons,
  PayPalMessages,
} from "@paypal/react-paypal-js"
import { Card, Alert, Row, Col } from "antd"

import { getSdkParams, checkOutSdk } from "../../services/offer.service"
import { useApply } from "../../hooks/useApply"
import { OfferOverNotification } from "../descriptions"

const PaypalOffer = ({ offer, isOverAmount }) => {
  const history = useHistory()
  const { lead, getLead } = useApply()

  const [initialOptions, setInitialOptions] = useState(null)
  const [orderId, setOrderId] = useState("")
  const [errorStatus, setErrorStatus] = useState("")

  const getInitialOptions = async () => {
    try {
      const response = await getSdkParams(offer.id)
      if (response.success) {
        const {
          attribution_id,
          client_id,
          currency,
          intent,
          enable_funding,
          disable_funding,
          //buyer_country,
          commit,
          components,
          debug,
          merchant_id,
          paypal_id,
        } = response.data

        setOrderId(paypal_id)
        setInitialOptions({
          clientId: client_id,
          currency,
          intent,
          enableFunding: enable_funding,
          disableFunding: disable_funding,
          //buyerCountry: buyer_country,
          commit,
          components,
          debug,
          merchantId: merchant_id,
          dataPartnerAttributionId: attribution_id,
        })
      }
    } catch (error) {
      setErrorStatus(error.message)
    }
  }

  const redirectToLoan = useCallback(async () => {
    if (lead.loans.length === 0) {
      history.push(`/apps/${lead.id}`)
    } else if (lead.loans.length === 1) {
      history.push(`/loans/${lead.loans[0].id}`)
    } else {
      const matchingLoan = lead.loans.find(
        (obj) => obj.originator.key === offer.originator.key
      )
      if (matchingLoan) {
        history.push(`/loans/${matchingLoan.id}`)
      } else {
        history.push(`/loans`)
      }
    }
  }, [history, lead, offer])

  useEffect(() => {
    if (offer?.status === "applied") {
      redirectToLoan()
    } else {
      getInitialOptions()
    }
    //eslint-disable-next-line
  }, [offer])

  useEffect(() => {
    if (lead.loans.length > 0) {
      if (lead.loans.length === 1) {
        history.push(`/loans/${lead.loans[0].id}`)
      } else {
        history.push(`/loans`)
      }
    }
    //eslint-disable-next-line
  }, [lead])

  return (
    <>
      {errorStatus && (
        <Alert
          message={errorStatus}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 7, width: "100%", textAlign: "left" }}
        />
      )}
      <OfferOverNotification lead={lead} isOverAmount={isOverAmount} />
      <Card bodyStyle={{ margin: 10 }} style={{ width: "100%" }}>
        <Row justify="center">
          <p style={{ fontSize: 20, margin: 0, fontWeight: 500 }}>
            You May Qualify for Monthly Payments with
          </p>
        </Row>
        <Row justify="center" style={{ marginTop: 10 }}>
          <img src="/logos/paypal.svg" width={120} alt="paypal_logo" />
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <p
            style={{
              fontSize: 15,
              margin: 0,
              fontWeight: 400,
              fontStyle: "italic",
            }}
          >
            Estimated payments below are based on your total cart value. If you
            apply without items in the cart you may be approved for $1,499.
          </p>
        </Row>
        <Card
          bodyStyle={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 40,
            borderLeftWidth: 0,
            borderRightWidth: 0,
          }}
        >
          <Row gutter={10}>
            <Col xs={12}>
              <div style={{ fontSize: 18, fontWeight: 600 }}>Choose PayPal</div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: "left",
                  marginTop: 10,
                  color: "gray",
                }}
              >
                No Interest with PayPal Credit if paid in full in 6 months.
              </div>
            </Col>
            <Col xs={12}>
              <div style={{ fontSize: 18, fontWeight: 600 }}>
                Choose Pay Later
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  textAlign: "left",
                  marginTop: 10,
                  color: "gray",
                }}
              >
                Pay in 4 interest-free or in 3, 6, 12, or 24-month plans.
              </div>
            </Col>
          </Row>
        </Card>
        {initialOptions && (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalMessages
              amount={offer.lead.amount}
              currency={initialOptions.currency}
              placement="product"
              style={{
                layout: "text",
                logo: { type: "inline" },
                text: {
                  size: 16,
                  align: "center",
                },
              }}
            />
            <div style={{ marginBottom: 30 }} />
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: offer.lead.amount,
                        currency_code: initialOptions.currency,
                      },
                    },
                  ],
                })
              }}
              onApprove={async (data, actions) => {
                try {
                  const response = await checkOutSdk(offer.id, "approve", data)
                  if (response.success) {
                    getLead(offer.lead.id)
                  }
                } catch (error) {
                  setErrorStatus(error.message)
                }
              }}
              onError={async (err) => {
                try {
                  await checkOutSdk(offer.id, "error", { err })
                } catch (error) {}
                setErrorStatus(err)
              }}
              onCancel={async (data, actions) => {
                try {
                  const response = await checkOutSdk(offer.id, "cancel", data)
                  if (response.success) {
                    getLead(offer.lead.id)
                  }
                } catch (error) {
                  setErrorStatus(error.message)
                }
                return orderId
              }}
            />
          </PayPalScriptProvider>
        )}
      </Card>
    </>
  )
}

export default PaypalOffer
