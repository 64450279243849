import React, { useEffect, useState } from "react"
import _ from "underscore"
import { useHistory } from "react-router-dom"
import { Card, notification, Row, Space } from "antd"
import { FormattedMessage } from "react-intl"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
import ApplyForm from "../forms/ApplyForm"
import Loader from "../core/Loader"
import {
  prepareLead,
  pingAndWait,
  updateLead,
} from "../../services/lead.service"
import BasicHeader from "../layout/BasicHeader"
import PoweredBy from "../core/PoweredBy"
import { ApplyCompliance } from "../compliance/ApplyCompliance"
import { Spinner } from "../core/Spinner"
import { formatDate } from "../../utils/formatters"

function ReApplyPage({ lead }) {
  const { setLead, merchant } = useApply()
  // const { merchant } = lead

  const { poweredByLogo } = useTheme()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledFields, setDisabledFields] = useState({
    personalFirstName: false,
    personalLastName: false,
    personalEmail: false,
    personalPhone: false,
    addressStreet1: false,
    addressStreet2: false,
    addressCity: false,
    addressState: false,
    addressZip: false,
  })
  const [defaultFields, setDefaultFields] = useState(null)
  const [fields, setFields] = useState(null)

  async function onSubmit(values, actions) {
    actions.setSubmitting(true)
    setLoading(true)
    setIsSubmitted(true)

    let leadObj = await prepareLead(values)

    const seonSessionPayload = sessionStorage.getItem("seonSessionPayload")
    if (seonSessionPayload) {
      leadObj.device_profiles = leadObj.device_profiles || []
      leadObj.device_profiles.push({
        provider: "seon",
        session_id: seonSessionPayload,
      })
    }

    let resp = await updateLead(lead.id, leadObj)

    if (resp.success) {
      try {
        const pingd = await pingAndWait(resp.data.id)
        console.log("pingd=====>", pingd)
        if (pingd.success) {
          setLoading(false)
          actions.setSubmitting(false)
          console.debug("Ping success data:", pingd.lead)
          setLead(pingd.lead)
          history.go(0)
        } else {
          notification.error({
            message: "Error",
            description: "Sorry, something went wrong. Please try again.",
            duration: 5,
          })
          history.push(`/apps/${lead.id}`)
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: error.data,
          duration: 5,
        })
        history.push(`/apps/${lead.id}`)
      }
    } else {
      notification.error({
        message: "Error",
        description: "There was an error processing your application",
        duration: 5,
      })
      _.keys(resp.error.data).forEach((k) => {
        if (_.isObject(resp.error.data[k])) {
          _.keys(resp.error.data[k]).forEach((kk) => {
            actions.setFieldError(`${k}.${kk}`, resp.error.data[k][kk][0])
          })
        } else {
          actions.setFieldError(k, resp.error.data[k][0])
        }
      })
      setIsSubmitted(false)
      setLoading(false)
    }
  }

  function setDefaults(fields) {
    return Object.entries(fields).reduce((acc, [k, v]) => {
      if (_.isObject(v)) {
        acc[k] = setDefaults(v)
      } else if (_.isBoolean(v)) {
        acc[k] = ""
      } else {
        acc[k] = v
      }
      return acc
    }, {})
  }

  function renderCompliance() {
    return (
      <Row style={{ textAlign: "center", paddingTop: 20 }}>
        <Space direction="vertical">
          <ApplyCompliance />
        </Space>
      </Row>
    )
  }

  useEffect(() => {
    if (merchant?.ping_fields) {
      const pingFields = _.omit(merchant.ping_fields, function (value) {
        if (_.isObject(value)) return _.keys(value).length === 0
        if (_.isBoolean(value)) return !value
        return false
      })

      const dFields = setDefaults(pingFields)
      setDefaultFields(dFields)
    }
    //eslint-disable-next-line
  }, [merchant])

  useEffect(() => {
    if (defaultFields && lead) {
      const newFields = { ...defaultFields }

      newFields.merchant = lead?.merchant
      newFields.amount = lead?.merchant.code === "citizens" ? 2000 : 1000
      newFields.purpose = "large_purchases"
      newFields.agree = false

      if ("personal" in defaultFields) {
        newFields.personal = {}

        if ("dob" in defaultFields.personal) {
          newFields.personal.dob = lead?.personal?.dob
            ? formatDate(lead.personal.dob, "MM/DD/YYYY")
            : ""
        }
        if ("email" in defaultFields.personal) {
          newFields.personal.email = lead?.personal?.email || ""
        }
        if ("first_name" in defaultFields.personal) {
          newFields.personal.first_name = lead?.personal?.first_name || ""
        }
        if ("last_name" in defaultFields.personal) {
          newFields.personal.last_name = lead?.personal?.last_name || ""
        }
        if ("phone" in defaultFields.personal) {
          newFields.personal.phone = lead?.personal?.phone || ""
        }
        if ("ssn" in defaultFields.personal) {
          newFields.personal.ssn = lead?.personal?.ssn || ""
        }
      }

      if (newFields?.income?.hasOwnProperty("annual_income")) {
        newFields.income["annual_income"] = lead?.income?.annual_income || ""
      }

      if (newFields?.income?.hasOwnProperty("net_monthly_income")) {
        newFields.income["net_monthly_income"] =
          lead?.income?.net_monthly_income || ""
      }

      if (newFields?.income?.hasOwnProperty("monthly_household_expense")) {
        newFields.income["monthly_household_expense"] =
          lead?.income?.monthly_household_expense || ""
      }

      if (newFields?.employment?.hasOwnProperty("employer_name")) {
        newFields.employment["employer_name"] =
          lead?.employment?.employer_name || ""
      }

      if (newFields?.employment?.hasOwnProperty("status")) {
        newFields.employment["status"] = lead?.income?.status || ""
      }

      newFields.address = {
        address_length: lead?.address?.address_length || "",
        city: lead?.address?.city || "",
        state: lead?.address?.state || "",
        status: lead?.address?.status || "",
        street1: lead?.address?.street1 || "",
        street2: lead?.address?.street2 || "",
        type: lead?.address?.type || "",
        zip: lead?.address?.zip || "",
      }
      setFields(newFields)
    }
  }, [defaultFields, lead])

  useEffect(() => {
    if (lead) {
      setDisabledFields({
        ...disabledFields,
        personalFirstName: lead.personal?.first_name ? true : false,
        personalLastName: lead.personal?.last_name ? true : false,
        personalEmail: lead.personal?.email ? true : false,
        personalPhone: lead.personal?.phone ? true : false,
        addressStreet1: lead.address?.street1 ? true : false,
        addressStreet2: lead.address?.street2 ? true : false,
        addressCity: lead.address?.city ? true : false,
        addressState: lead.address?.state ? true : false,
        addressZip: lead.address?.zip ? true : false,
      })
    }
    /* eslint-disable */
  }, [lead])

  if (!fields) {
    return <Spinner />
  }

  return (
    <Card>
      <BasicHeader
        text={
          <FormattedMessage
            id="ApplyPage.header"
            defaultMessage="Complete your application to get offers instantly!"
          />
        }
      />
      <Loader
        visible={loading}
        title={
          <FormattedMessage
            id="ApplyPage.loaderTitle"
            default="Searching For Offers"
          />
        }
        body={
          <FormattedMessage
            id={
              lead?.merchant.code === "fortiva"
                ? "ApplyPage.loaderBody.fortiva"
                : "ApplyPage.loaderBody"
            }
            defaultMessage="Please while while we search our lender network"
          />
        }
      />
      <ApplyForm
        values={fields}
        submitText={
          <FormattedMessage
            defaultMessage="Search for Offers"
            id={
              lead.merchant.code === "trek"
                ? "buttons.apply.trek"
                : "ApplyForm.submit"
            }
          />
        }
        onSubmit={onSubmit}
        isSubmitted={isSubmitted}
        additionalCompliance={null}
        merchantCode={lead.merchant.code}
        disabledFields={disabledFields}
      />
      {renderCompliance()}
      {poweredByLogo && <PoweredBy />}
    </Card>
  )
}

export default ReApplyPage
