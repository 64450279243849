import React from "react"
import { Formik } from "formik"
import { Form, DatePicker } from "formik-antd"
import { Typography, Row, Col, Button } from "antd"
import { PayrollFrequencySelect, MoneyInput } from "../inputs"
import { validateFuture, validatePast } from "../../utils/validation"

const { Title, Text } = Typography

export default function UownForm({
  values,
  submitText,
  onSubmit,
  isSubmitted,
  setIsSubmitted,
}) {
  function renderIncome(formik) {
    if (!values.hasOwnProperty("income")) return null

    return (
      <>
        <Title style={{ textAlign: "left" }} level={5}>
          Income Information
        </Title>
        <Text>Additional income information is needed by this provider.</Text>
        <br />
        <br />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <MoneyInput
              name="income.net_monthly_income"
              min={1000}
              hasFeedback
              label="Net Monthly Income"
              tooltip="Your monthly income after taxes."
              formik={formik}
              placeholder="$ Net Monthly Income"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <PayrollFrequencySelect />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.last_pay_date"
              label="Last Pay Date"
              hasFeedback
              validate={validatePast}
            >
              <DatePicker
                name="income.last_pay_date"
                size="large"
                // defaultValue={moment(moment().subtract(1, 'days'), 'YYYY-MM-DD')}
                format="MM/DD/YYYY"
                style={{ width: "100%" }}
                label="Last Pay Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="income.next_pay_date"
              label="Next Pay Date"
              hasFeedback
              validate={validateFuture}
            >
              <DatePicker
                name="income.next_pay_date"
                size="large"
                format="MM/DD/YYYY"
                // defaultValue={moment(moment().add(1, 'days'), 'YYYY-MM-DD')}
                style={{ width: "100%" }}
                label="Next Pay Date"
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  const handleAppy = (formik) => {
    if (Object.keys(formik.errors).length === 0) {
      setIsSubmitted(true)
      formik.handleSubmit()
    }
  }

  return (
    <Formik initialValues={values} onSubmit={onSubmit}>
      {(formik) => (
        <Form layout="vertical" style={{ width: "100%" }}>
          {renderIncome(formik)}
          <Row justify="center">
            <div style={{ marginTop: 20 }}>
              <Button
                type="primary"
                onClick={() => handleAppy(formik)}
                disabled={isSubmitted}
              >
                {submitText}
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
