import { validateEmail, validatePassword } from "../hooks/requests"
import { Link } from "react-router-dom"
import moment from "moment"

function validateDate(value) {
  if (value === null || value === undefined || value === "")
    return "Please enter a valid date."
  if (value.length < 10) return "Please enter a valid date."
  const date = moment(value)
  if (!date.isValid()) return "Please enter a valid date."

  const now = moment()
  const difference = now.diff(date, "years")

  if (difference > 100 || difference < 0) return "Please enter a valid date."

  return undefined
}

export function validateBirthday(value) {
  if (value.length < 10) return "Please enter a valid date."
  const birthday = moment(value)
  if (!birthday.isValid()) return "Please enter a valid date."

  const now = moment()
  const difference = now.diff(birthday, "years")

  if (difference > 100) return "Please enter a valid date."
  if (difference < 18)
    return "You must be at least 18 years old to be eligible."

  return undefined
}

export function validateFuture(value) {
  const basicValidation = validateDate(value)
  if (typeof basicValidation === "string") return basicValidation
  const date = moment(value)
  if (date.isAfter()) {
    return undefined
  }

  return "Please enter a date in the future"
}

export function validatePast(value) {
  const basicValidation = validateDate(value)
  if (typeof basicValidation === "string") return basicValidation
  const date = moment(value)
  if (date.isBefore()) {
    return undefined
  }

  return "Please enter a date in the past"
}

export const ValidateEmailInput = async (email, test = true, errorMessage) => {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    if (test) {
      const request = validateEmail(email)
      try {
        const response = await fetch(request[0], request[1])
        if (response.status === 200) return undefined
        const result = await response.json()
        if (
          result.email[0] ===
          "A user is already registered with this e-mail address."
        ) {
          localStorage.setItem("loggedEmail", email)
          return (
            <>
              {result.email[0]} If you already have an account, please{" "}
              <Link to="/login">Login</Link>
            </>
          )
        }
      } catch (err) {
        return "Please enter a valid email address"
      }
    }
  } else {
    return errorMessage || "Please enter a valid email"
  }
}

export const ValidatePasswordInput = async (password) => {
  const request = validatePassword(password)
  try {
    const response = await fetch(request[0], request[1])
    if (response.status === 200) return undefined
    const js = await response.json()
    return js.password.join(" ")
  } catch (err) {
    return "Invalid password - Must contain at least 8 characters"
  }
}

export const ValidateAddressInput = async (address, errorMessage) => {
  const addressRegex = /^[a-zA-Z0-9\s-]*$/
  const multipleSpacesRegex = /\s{2,}/
  const spacePeriodRegex = /\s\./

  if (address.length !== 0) {
    if (!addressRegex.test(address)) {
      return `Addresses can only contain numbers, letters, spaces and hyphens.`
    }
    if (multipleSpacesRegex.test(address)) {
      return "Address cannot contain multiple consecutive spaces"
    }
    if (spacePeriodRegex.test(address)) {
      return "Address cannot contain a space followed by a period"
    }
  } else {
    return errorMessage || "Please enter a valid address"
  }
}
