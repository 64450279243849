import React from "react"
import { Card, Row, Typography, Button } from "antd"
import { FormattedMessage } from "react-intl"

import { formatLoan } from "../../utils/formatters"
import { useApply } from "../../hooks/useApply"
import { OfferOverNotification } from "../descriptions"

const { Text } = Typography

function OwnLeaseOffer({ offer, disclaimer, acceptOffer, isOverAmount }) {
  const { lead } = useApply()

  return (
    <>
      <OfferOverNotification lead={lead} isOverAmount={isOverAmount} />
      <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
        <Row justify="center">
          <img src={offer.originator.image_url} alt="lender-logo" height={28} />
        </Row>

        <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
          <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>
            You've Been Pre-Qualified* By {offer.originator.name}
          </p>
        </Row>
        <div
          justify="center"
          style={{ marginTop: 0, marginBottom: 0, paddingBottom: 0 }}
        >
          <p style={{ fontSize: 20, margin: 0, fontWeight: 400 }}>
            {formatLoan(offer.loan_type)} **
          </p>
        </div>

        <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>
          {offer.amount}
        </p>
        <p style={{ fontSize: 18, margin: 0, fontWeight: 500 }}>Cart Total</p>
        <br />

        {offer.term ? (
          <div>
            <b>
              <FormattedMessage id={"general.term"} />
              :&nbsp;&nbsp;
            </b>
            {offer.term}
            &nbsp;&nbsp;
          </div>
        ) : null}
        {offer.monthly_payment ? (
          <div>
            <b>
              <FormattedMessage id="general.monthlyPayment" />
              :&nbsp;&nbsp;
            </b>
            {offer.monthly_payment}
          </div>
        ) : null}

        {offer.payment ? (
          <div>
            <b>
              <FormattedMessage id="general.payment" />
              :&nbsp;&nbsp;
            </b>
            {offer.payment}
          </div>
        ) : null}

        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" onClick={acceptOffer}>
            Continue
          </Button>
        </Row>

        <p
          style={{
            marginTop: 15,
            fontStyle: "italic",
            fontSize: 11,
            textAlign: "left",
          }}
        >
          <FormattedMessage id="AffCompliance.ltoPrequal" />
        </p>

        <p
          style={{
            marginTop: 15,
            fontStyle: "italic",
            fontSize: 11,
            textAlign: "left",
            whiteSpace: "pre-line",
          }}
        >
          <FormattedMessage id="AffCompliance.ltoDiscolsure" />
        </p>
        <Row justify="center" style={{ marginTop: 10 }}>
          {
            <Row
              justify="center"
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontSize: 10,
                textAlign: "center",
              }}
            >
              <Text>{disclaimer || offer.originator.disclaimer}</Text>
            </Row>
          }
        </Row>
      </Card>
    </>
  )
}
export default OwnLeaseOffer
