import React, { useState } from "react"
import GenesisDiscosure from "../lenders/GenesisDiscosure"
import GenesisApprovalCard from "../lenders/GenesisApprovalCard"

function GenesisOffer(props) {
  const [showDisclosures, setShowDisclosures] = useState(false)

  if (showDisclosures) {
    return (
      <GenesisDiscosure acceptOffer={props.acceptOffer} offer={props.offer} />
    )
  }

  return (
    <GenesisApprovalCard
      merchant={props.merchant}
      offer={props.offer}
      onReviewDisclosures={() => setShowDisclosures(true)}
      isOverAmount={props.isOverAmount}
    />
  )
}

export default GenesisOffer
