import React, { useEffect } from "react"
import { Modal, Row, Button } from "antd"
import { useTheme } from "../../hooks/useTheme"

export default function FrameModal({
  showModal,
  title,
  setShow,
  url,
  setLoading,
}) {
  const { isMobile } = useTheme()

  const handleLoading = () => {
    if (setLoading) {
      setLoading(false)
    }
  }

  const closeModal = () => {
    setShow(false)
  }

  useEffect(() => {
    const handleMessage = (event) => {
      window.parent && window.parent.postMessage(event.data, "*")

      if (event.data?.type === "order" && event.data?.status === "approved") {
        setTimeout(closeModal, 2000)
      }
    }

    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
    /* eslint-disable */
  }, [])

  return (
    <Modal
      visible={showModal}
      title={title}
      width={isMobile ? "100vw" : "80vw"}
      centered
      footer={
        true ? null : (
          <Row justify="center">
            <Button type="primary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Row>
        )
      }
      bodyStyle={{ height: isMobile ? "85vh" : "85vh", padding: 0, margin: 0 }}
      onCancel={() => setShow(false)}
      onOk={() => setShow(false)}
    >
      <iframe
        title={title}
        height="100%"
        frameBorder="0"
        width="100%"
        onLoad={handleLoading}
        src={url}
      />
      <a href={url} rel="noreferrer" target="_blank">
        Open In New Window
      </a>
    </Modal>
  )
}
