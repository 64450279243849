import React, { useMemo } from "react"
import { Row, Modal, Button, Typography } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import _ from "underscore"
import { FormattedMessage } from "react-intl"
import { useTheme } from "../../hooks/useTheme"
// import { formatLoan } from "../utils/formatters";
import copy from "../../utils/appCopy"

const { Title } = Typography

function NextSteps({ offer }) {
  // const lenderCopy = copy[offer.originator.key]
  console.log(offer)
  const lenderCopy = _.has(copy, offer.originator.key)
    ? copy[offer.originator.key]
    : copy.default

  return (
    <p style={{ fontSize: 14, fontWeight: 250 }}>
      {lenderCopy[offer.loan_type]?.next_steps}
    </p>
  )
}

export default function OfferSummary(props) {
  const { colors, isMobile } = useTheme()
  const lenderCopy = _.has(copy, props.offer.originator.key)
    ? copy[props.offer.originator.key]
    : copy.default
  // const lenderCopy = copy[props.offer.originator.key]

  function renderOfferType() {
    return (
      <p style={{ fontSize: 14, fontWeight: 250 }}>
        {lenderCopy[props.offer.loan_type]?.summary}
      </p>
    )
  }

  const preQualified = useMemo(() => {
    switch (props.offer.originator.key) {
      case "genesis":
        return `You've been prequalified for a Concora™ Credit Account, issued by ${props.offer.bank_name}`
      default:
        return `You've been Pre-Qualified* by ${props.offer.originator.name}.`
    }
  }, [props.offer])

  const applyButton = useMemo(() => {
    switch (props.offer.originator.key) {
      case "flexshopper":
        return "Apply Now"
      case "flexshopper-external":
        return "Apply Now"
      default:
        return "View Offer"
    }
  }, [props.offer.originator.key])

  return (
    <Modal
      visible={props.visible}
      cancelText={null}
      centered={!!isMobile}
      style={{ verticalAlign: "top" }}
      title={
        <>
          <Row justify="center" style={{ marginTop: 10 }}>
            <CheckCircleOutlined
              style={{ color: colors.green, fontSize: isMobile ? 32 : 48 }}
            />
          </Row>

          <Row justify="center" style={{ marginTop: 10 }}>
            <Title style={{ textAlign: "center" }} level={3}>
              <FormattedMessage
                id="general.congratulations"
                defaultMessage="Congratulations"
              />
              !
            </Title>
          </Row>
        </>
      }
      footer={
        <Row justify="center">
          <Button
            onClick={props.close}
            style={{ justify: "center" }}
            type="primary"
          >
            {applyButton}
          </Button>
        </Row>
      }
      closable={false}
    >
      <Row justify="">
        <img
          src={props.offer.originator.image_url}
          alt="lender-logo"
          height={isMobile ? 28 : 32}
        />
      </Row>
      <br />
      <Title level={5}>{preQualified}</Title>
      {renderOfferType()}
      <Title level={5}>Next Steps</Title>
      <NextSteps offer={props.offer} />
    </Modal>
  )
}
