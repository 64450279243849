import React from "react"
import { Card, Row, Typography, Button } from "antd"
import { FormattedMessage } from "react-intl"
import { OfferOverNotification } from "../descriptions"
import { useApply } from "../../hooks/useApply"

const { Text } = Typography

function UownOffer({ offer, disclaimer, acceptOffer, isOverAmount }) {
  const { lead } = useApply()

  return (
    <>
      <OfferOverNotification lead={lead} isOverAmount={isOverAmount} />
      <Card bodyStyle={{ margin: 0 }} style={{ width: "100%" }}>
        <Row justify="center">
          <img src={offer.originator.image_url} alt="lender-logo" height={28} />
        </Row>

        <Row justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
          <p style={{ fontSize: 22, margin: 0, fontWeight: 300 }}>
            You've Been Approved* By {offer.originator.name} Purchase Up To
          </p>
        </Row>

        <p style={{ fontSize: 24, margin: 0, fontWeight: 300 }}>
          {offer.amount}
        </p>
        <br />

        {offer.term ? (
          <div>
            <b>Obtain Ownership in Up To:&nbsp;&nbsp;</b>
            {offer.term}
            &nbsp;&nbsp;
          </div>
        ) : null}

        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" onClick={acceptOffer}>
            Continue
          </Button>
        </Row>

        <p
          style={{
            marginTop: 15,
            fontStyle: "italic",
            fontSize: 11,
            textAlign: "left",
          }}
        >
          <FormattedMessage id="UownCompliance.ltoPrequal" />
        </p>

        <p
          style={{
            marginTop: 15,
            fontStyle: "italic",
            fontSize: 11,
            textAlign: "left",
            whiteSpace: "pre-line",
          }}
        >
          <FormattedMessage id="UownCompliance.ltoDiscolsure" />
        </p>
        <Row justify="center" style={{ marginTop: 10 }}>
          {
            <Row
              justify="center"
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontSize: 10,
                textAlign: "center",
              }}
            >
              <Text>{disclaimer || offer.originator.disclaimer}</Text>
            </Row>
          }
        </Row>
      </Card>
    </>
  )
}
export default UownOffer
