import React, { useEffect, useState, useMemo, useCallback } from "react"
import { Row, Col, Typography, Button, Input, Card } from "antd"
import { useHistory } from "react-router-dom"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
import PoweredBy from "../core/PoweredBy"
import { ProductCard, CategoryCard } from "../../components/cards"
import { getProducts, getCategories } from "../../services/product.service"
import { Spinner } from "../core/Spinner"
import { getCurrentDate } from "../../utils/formatters"

const { Text } = Typography

function ProductSelectLander(props) {
  const { merchant, login, img } = props
  const { merchantId, cartsList, cartTotal, updateCartList, removeCartList } =
    useApply()
  const { push } = useHistory()
  const { poweredByLogo } = useTheme()
  const [searchKey, setSearchKey] = useState("")
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const [preUrl, setPreUrl] = useState(null)
  const [catNextUrl, setCatNextUrl] = useState(null)
  const [catPreUrl, setCatPreUrl] = useState(null)
  const [selectCatId, setSelectCatId] = useState(null)
  const [debouncedSearchKey, setDebouncedSearchKey] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSearch = async () => {
    let uri = `${merchant.business.url}products/?is_active=1&search=${debouncedSearchKey}`
    if (selectCatId) {
      uri = `${merchant.business.url}products/?is_active=1&category_id=${selectCatId}&search=${debouncedSearchKey}`
    }
    setLoading(true)
    const result = await getProducts(uri)
    setProdcutResults(result)
    setLoading(false)
  }

  const handlePagination = async (url) => {
    if (selectCatId) {
      setLoading(true)
      const result = await getProducts(url)
      setProdcutResults(result)
      setLoading(false)
    } else {
      setLoading(true)
      const result = await getCategories(url)
      setCategoryResults(result)
      setLoading(false)
    }
  }

  const setProdcutResults = (result) => {
    if (result.success) {
      setProducts(result.data?.results)
      setNextUrl(result.data?.next)
      setPreUrl(result.data?.previous)
    } else {
      setProducts([])
      setNextUrl(null)
      setPreUrl(null)
    }
  }

  const setCategoryResults = (result) => {
    if (result.success) {
      setCategories(result.data?.results)
      setCatNextUrl(result.data?.next)
      setCatPreUrl(result.data?.previous)
    } else {
      setCategories([])
      setCatNextUrl(null)
      setCatPreUrl(null)
    }
  }

  const selectProduct = (item) => {
    updateCartList(item)
    // if (merchant?.code === "ace-hardware") {
    //   updateCartList(item)
    // } else {
    //   setProduct(item)
    //   if (callback) {
    //     callback(item)
    //   } else {
    //     push("/apply")
    //   }
    // }
  }

  const isExistProduct = useCallback(
    (item) => {
      const data = cartsList.length > 0 ? [...cartsList] : []
      const itemExists = data.some((cartItem) => cartItem.id === item.id)
      if (itemExists) return true
      return false
    },
    [cartsList]
  )

  const handleContinue = async () => {
    push("/apply")
  }

  const displayLogo = useMemo(() => {
    const aceSteps = [
      "Select the products you would like to purchase.",
      "Provide some basic personal details.",
      "Check if you prequalify with no impact to your credit score.",
      "Accept your offer and submit an application.",
      "Review and approve your order  to complete the purchase.",
    ]
    return (
      <div style={{ paddingTop: "18rem" }}>
        <Row align="middle" justify="center">
          <img src={img} width="30%" alt="merchant-logo" />
        </Row>
        <Row align="middle" justify="center">
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 18,
              lineHeight: "28px",
            }}
          >
            {merchant?.name} - {merchant?.address?.city}
          </Text>
        </Row>
        <Row align="middle" justify="center">
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {merchant?.address?.street1}, {merchant?.address?.city},{" "}
            {merchant?.address?.state}, {merchant?.address?.zip}
          </Text>
        </Row>
        <Row
          align="middle"
          style={{
            borderTop: "2px",
            border: "2px solid black",
            borderLeft: 0,
            borderRight: 0,
            marginTop: 15,
            padding: 10,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 18,
              lineHeight: "28px",
              width: "100%",
              textDecoration: "underline",
            }}
          >
            Check if you Pre-Qualify
          </Text>
          {aceSteps.map((step, index) => (
            <Row align="middle" style={{ marginTop: 3 }} key={index}>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 15,
                }}
              >
                Step{index + 1}:{" "}
                <Text
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {step}
                </Text>
              </Text>
            </Row>
          ))}
        </Row>

        {cartsList.length > 0 &&
          cartsList.map((cart, index) => (
            <ProductCard
              key={index}
              item={cart}
              removeProduct={removeCartList}
              selectProduct={selectProduct}
              isQTY={true}
            />
          ))}
        {cartsList.length > 0 && (
          <>
            <Row
              align="middle"
              justify="space-between"
              style={{
                border: "2px solid black",
                borderTop: cartsList.length > 0 ? 2 : 0,
                borderLeft: 0,
                borderRight: 0,
                padding: 10,
                fontWeight: 600,
              }}
            >
              <div>Cart Total:</div>
              <div>$ {cartTotal.toLocaleString()}</div>
            </Row>
            {merchant?.code === "ace-hardware" && (
              <>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Purchase Amount:</div>
                  <div>$ {cartTotal.toLocaleString()}</div>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Sugg. Payments:</div>
                  <div>$ {Number(cartTotal / 6).toFixed(2)} / mo</div>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  <div>Term:</div>
                  <div>6mos</div>
                </Row>
              </>
            )}
          </>
        )}

        <Row
          align="middle"
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Button
            type="primary"
            onClick={handleContinue}
            disabled={cartsList.length === 0}
          >
            Check Eligibility
          </Button>
        </Row>
        {merchant?.code === "ace-hardware" && cartsList.length > 0 && (
          <Card
            bodyStyle={{
              paddingBottom: 0,
              paddingRight: 10,
              paddingLeft: 10,
            }}
            style={{
              width: "100%",
              maxHeight: 250,
              height: "100%",
              overflowY: "auto",
              marginTop: 20,
            }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: 20,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              Disclosures
            </div>
            <div style={{ fontWeight: 400, fontSize: 15 }}>
              ¹This is an example of an offer that may be available from our
              lending partner, Citi. If you do not pre-qualify for Citi Pay
              Credit, PayPossible may share your application information with
              other financial providers to see if you may be eligible for other
              financing options. <br />
              <br />
              ²Subject to credit approval. No interest if paid in full within
              the promotional period. Interest will be charged to your account
              if the purchase balance is not paid in full within the promotional
              period. <br />
              <br />
              This Deferred Interest Plan purchase is subject to the following
              promotional terms: you will not be charged any interest on the
              amount of the purchase if that amount is paid in full within 6
              months. <br />
              <br />
              If the amount of the purchase is not paid in full by the end of
              the promotional period, interest charges will be imposed from the
              purchase date at the purchase rate on your account, which is %
              APR. This APR is accurate as of {getCurrentDate()}. This APR is
              variable and will vary with the market based on the Prime Rate.{" "}
              <br />
              Your Card Agreement, this Promotional Disclosure and applicable
              law govern the terms of this Deferred Interest Plan purchase.
              <br />
              <br />
              The suggested monthly payment stated is an estimate based on
              listed purchase amount divided by the number of months in the
              promotional period. It excludes sales tax, is rounded to the next
              highest cent and does not include additional payments that may be
              required for an existing balance or future purchases. In order to
              pay your promotional balance by the end of the period you may have
              to make a payment each month that is higher than your required
              minimum payment.
            </div>
          </Card>
        )}
      </div>
    )
    // eslint-disable-next-line
  }, [merchant, img, cartsList])

  useEffect(() => {
    if (merchant?.business?.url) {
      ;(async function () {
        const result = await getCategories(merchant.business.url)
        setCategoryResults(result)
      })()
    }
  }, [merchant])

  useEffect(() => {
    if (debouncedSearchKey || selectCatId) {
      handleSearch()
    }
    // eslint-disable-next-line
  }, [debouncedSearchKey, selectCatId])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchKey(searchKey)
    }, 500)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line
  }, [searchKey, 500])

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        height: "100%",
        position: "relative",
      }}
    >
      <Row align="middle" justify="space-around">
        <Col
          style={{
            backgroundColor: "#ffffff",
            maxWidth: 380,
            width: "100%",
            position: "relative",
          }}
        >
          {process.env.REACT_APP_FE === "bolster" && (
            <div
              style={{
                marginBottom: 30,
                backgroundColor: "#ffffff",
                maxWidth: 380,
                width: "100%",
                margin: "0 auto",
                zIndex: 1,
                position: "fixed",
                paddingTop: 20,
              }}
            >
              <div style={{ fontSize: 15 }}>
                <Text strong>Flexible Financing</Text> : Citi and other lenders
                through PayPossible
              </div>
              <div
                style={{
                  border: "1px solid #5b96c5",
                  borderRadius: 10,
                  padding: 15,
                  width: "100%",
                }}
              >
                <Row align="middle" justify="space-around">
                  <img
                    src={"/lenders/citi.png"}
                    width="30%"
                    alt="merchant-logo"
                  />
                  <div
                    style={{
                      border: "1px solid #5b96c5",
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      paddingBottom: 5,
                    }}
                  >
                    Sample offer from <strong>Citi Pay®</strong> Credit¹
                  </div>
                </Row>
                <div style={{ fontWeight: 600, marginTop: 10 }}>
                  No interest financing on $180+ purchases, if paid in full
                  within 6 mos².
                </div>
                <div style={{ marginTop: 10 }}>
                  Interest will be charged to your account from the purchase
                  date if the purchase balance is not paid in full within 6
                  months.
                </div>
              </div>
            </div>
          )}
          {displayLogo}
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "28px",
              }}
            >
              Please enter the Product Name or SKU of the product you'll be
              purchasing
            </Text>
          </Row>
          <Row
            justify="center"
            style={{ marginTop: 20, height: 45, width: "100%" }}
          >
            <Input
              placeholder="Product Name/SKU"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </Row>
          <Row align="middle" justify="center">
            <Button
              shape="default"
              style={{
                color: "white",
                backgroundColor: "black",
                marginTop: 20,
                padding: "0px 30px",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Row>
          {selectCatId && (
            <Row align="middle" justify="center">
              <Button shape="link" onClick={() => setSelectCatId("")}>
                {`<`} Back to search categories
              </Button>
            </Row>
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Row
              align="middle"
              justify="center"
              style={{ marginTop: 20, width: "100%" }}
            >
              {searchKey || selectCatId
                ? products.map((item, index) => (
                    <ProductCard
                      key={index}
                      item={item}
                      selectProduct={selectProduct}
                      btnText={"Add to Cart"}
                      disabled={isExistProduct(item)}
                    />
                  ))
                : categories?.map((item, index) => (
                    <CategoryCard
                      item={item}
                      setSelectCatId={setSelectCatId}
                      key={index}
                    />
                  ))}

              {(categories.length > 0 || products.length > 0) && (
                <Row
                  align="middle"
                  justify="space-around"
                  style={{ marginTop: 30 }}
                >
                  <Button
                    type="primary"
                    style={{
                      marginRight: 7,
                    }}
                    disabled={(selectCatId ? preUrl : catPreUrl) === null}
                    onClick={() =>
                      handlePagination(selectCatId ? preUrl : catPreUrl)
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 7,
                    }}
                    disabled={(selectCatId ? nextUrl : catNextUrl) === null}
                    onClick={() =>
                      handlePagination(selectCatId ? nextUrl : catNextUrl)
                    }
                  >
                    Next
                  </Button>
                </Row>
              )}
            </Row>
          )}
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 15,
                lineHeight: "28px",
              }}
            >
              {merchantId === "trek" && (
                <>
                  Checking eligibility{" "}
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    will not impact
                  </Text>{" "}
                  your credit score
                </>
              )}
            </Text>
          </Row>
          {poweredByLogo && <PoweredBy />}
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 30 }}>
        {login && login}
      </Row>
    </div>
  )
}

export default ProductSelectLander
